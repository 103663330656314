<template>

    <div class="px-4 py-12 text-[#767472] border w-full">

        <SearchBar page="details" />

    </div>

</template>


<script>
import SearchBar from './SearchBar.vue';

export default {
    components: {
        SearchBar
    },
}

</script>